.header_container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: end;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.verity_main {
  display: flex;
  max-width: 100vw;
}

.right_content {
  width: 100%;
  overflow-x: hidden;
}
