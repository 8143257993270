.signUpForm {
  border: 1px solid black;
  width: 100%;
  height: auto;
  border-radius: 2%;
  padding: 5%;
}

.input_div {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 3.2rem;
  margin-top: 0;
  font-weight: 1000;
}

.label {
  font-size: 2.5rem;
}

.input {
  margin-top: 1%;
  height: 2.4rem;
  font-size: 1rem;
  border: none; /* <-- This thing here */
  border-radius: 10px;
  background-color: #dbf2f6;
  padding-left: 2%;
}

.options_container {
  font-size: 1.2rem;
  cursor: pointer;
}

.button_container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.button {
  height: 3rem;
  font-size: 2rem;
  width: 15rem;
  background-color: #6cc6da;
  color: white;
  border-radius: 8px;
  border: none;
  align-self: center;
  cursor: pointer;
  margin-top: 3%;
}
